import React from "react";
import { GoodMorningBusiness } from "../../../Components/GoodMorningBusinessComponent";
import { OfferComponent } from "../../../Components/OfferComponent";
import { ReviewsProgressComponent } from "../../../Components/Reviews/ReviewsProgressComponent";
import { Row, Container, Col } from "react-bootstrap";

const WidgetsComponent = () => {
  return (
    <Container fluid="lg" className="mt-3">
      <Row className="mb-3 mb-md-0 g-3 g-xl-4">
        <Col md={6} xl={4} className="order-1 order-md-0 0 mb-3 mb-lg-4">
          <ReviewsProgressComponent />
        </Col>
        <Col xl={4} className="d-md-none d-xl-block order-2 order-md-1mb-3 mb-lg-4">
          <GoodMorningBusiness />
        </Col>
        <Col md={6} xl={4} className="order-0 order-md-2 mb-3 mb-lg-4">
          <OfferComponent />
        </Col>
      </Row>
    </Container>
  );
};

export default WidgetsComponent;
