import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal'
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import { useTranslation } from "react-i18next";

const ModalVideoComponent = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t } = useTranslation();
    const videoSrc = {
        type: "video",
        sources: [{src: "JwhhceMwmIk", provider: "youtube"}]
    };
    const options = {
        autoplay: true,
    };

    return (
    <>
        <div onClick={handleShow} className="d-inline-block text-white stretched-link small line-height-sm cursor-pointer">
            <span className="d-inline-block rounded-pill bg-primary pe-2 fw-bold">
                <span className="d-inline-flex bg-white border border-lg border-primary p-1 rounded-circle me-1">
                    <i className="text-primary fa-play fas"/>
                </span>
                {t("good-morning-widget.button")}
            </span>
        </div>
        <Modal show={show} onHide={handleClose} size="lg" centered animation={false}>
            <Modal.Body className="p-0">
                <div className="embed-responsive-16by9">
                    <Plyr options={options} source={videoSrc} />
                </div>
            </Modal.Body>
        </Modal>
    </>
    )
}

export default ModalVideoComponent;

