import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

export const OfferComponent = () => {
  const { t } = useTranslation();
  return (
      <div className="text-center d-flex flex-column align-items-center subscription bg-light rounded-3 p-3 h-100 cursor-pointer">
        <div className="m-auto">
          <span className="d-block display-1 text-dark fw-bold text-uppercase mb-1">
            {t("offer-widget.title")}
          </span>
          <span className="d-block text-dark text-uppercase mb-1">
            <Trans>{t("offer-widget.subtitle")}</Trans>
          </span>
          <div className="text-dark text-uppercase fw-bold mb-1">
            {t("offer-widget.trial")}
          </div>
          <span className="d-block text-uppercase text-primary fw-bold large">
            <Trans>{t("offer-widget.prices.2")}</Trans>
          </span>
          <span className="d-block small text-primary fw-semibold">
            {t("offer-widget.permanence")}
          </span>
          <div className="text-secondary fw-semibold small mt-2">
            {t("offer-widget.monthly")}
          </div>
          <span className="d-block small text-muted text-uppercase mt-2">
            {t("offer-widget.alternativeTags")}
          </span>
        </div>
      </div>
  );
};
