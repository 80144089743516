import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from "../../Pages/HomePage";

export const BaseRouter = () => {
  return (
    <Router>
        <Routes>
            <Route exact path="/" element={<HomePage />} />
        </Routes>
    </Router>
  );
};
