import React, {useState, useEffect} from 'react';
import {scrollTo} from "../../../utils/scrollTo";
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';

const searchCompanies = async (search = '', page = 1) => {
    let params = {'search': search, 'page': page};
    let urlencoded = new URLSearchParams(params);
    let requestOptions = {
        crossDomain: true,
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        body: urlencoded,
        redirect: 'follow'
    };
    let route = process.env.REACT_APP_URL_COMPANIES_SEARCH;
    return await fetch(route, requestOptions)
        .then(response => response.json())
        .then(companies => companies)
        .catch(error => []);
};
const createPagination = (currentPage, totalPages) => {
    let minPage = 1;
    let maxPage = totalPages;
    let pages = [];
    let numbersDisplayed = 4;
    if (currentPage > minPage) {
        let previous = currentPage - 1;
        pages.push({'icon': '<', 'page': previous});
    }
    if (currentPage + numbersDisplayed <= totalPages) {
        maxPage = currentPage + numbersDisplayed;
    }
    if (currentPage - numbersDisplayed > 0) {
        minPage = currentPage - numbersDisplayed;
    }
    for (let i = minPage; i < maxPage; i++) {
        let page = {'icon': i, 'page': i};
        if (i === currentPage) {
            page.class = 'active';
        }
        pages.push(page);
    }
    if (currentPage !== totalPages) {
        let next = currentPage + 1;
        pages.push({'icon': '>', 'page': next});
    }
    return pages;
};

const getUrlParameter = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
const updateQueryStringParameter = (uri, key, value) => {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    return uri + separator + key + "=" + value;
}

const CompaniesComponent = () => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState();
    const [search, setSearch] = useState();
    const [pages, setPages] = useState();
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [loadingUserRequest, setLoadingUserRequest] = useState(false);

    useEffect(() => {
        if (getUrlParameter('infonetSearch')) {
            setSearch(getUrlParameter('infonetSearch'));
            renderCompanies(1, false, getUrlParameter('infonetSearch'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCompanies = async (page = 1, scroll = false, forceSearch = '') => {
        if (loadingCompanies) {
            return;
        }
        setLoadingCompanies(true);
        if (scroll) {
            scrollTo('table-container');
        }
        let result = await searchCompanies(forceSearch || search, page);
        setCompanies(result['companies']);
        setPages(createPagination(result['currentPage'], result['totalPages']));
        setLoadingCompanies(false);
    }

    const redirectParentTo = (i, url) => {
        setLoadingUserRequest(i);
        // If page is loaded in iframe, reload the parent
        if (window.location !== window.parent.location) {
            window.parent.location = url;
        } else {
            window.location = url;
        }
    };
    const redirectToCompaniesPage = () => {
        const url = updateQueryStringParameter(document.referrer, 'infonetSearch', search || '');
        // If page is loaded in iframe, reload the parent
        if (window.location !== window.parent.location) {
            window.parent.location = url;
        } else {
            window.location = url;
        }
    };

    return (
    <>
        <Row className="mb-3 mt-2">
            <form name="company_search" className="search-company-form" onSubmit={(e) => {e.preventDefault(); redirectToCompaniesPage();}}>
                <div className={`col-xl-9 col-lg-10 mx-auto ${loadingCompanies ? 'd-none' : ''}`}>
                    <div className="d-flex shadow bg-white rounded-pill">
                        <div className="input-group">
                            <div className="flex-fill">
                                <input type="text" id="company_search_query" name="company_search[query]" className="form-control-lg rounded-pill border-0 ps-3 pe-0 form-control text-truncate" placeholder={t('companies.company')} onChange={(e) => setSearch(e.target.value)} value={search || ''}/>
                            </div>
                            <Button variant="link" type="submit" id="company_search_submit" name="company_search[submit]" className="text-dark px-2 me-2">
                                <i className={loadingCompanies ? 'fa-spin fa-spinner fa-lg fas' : 'fa-search fa-lg fas'}/>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`mx-auto ${!loadingCompanies ? 'd-none' : ''}`}>
                    <div className="d-inline-block shadow bg-white rounded-pill p-3 text-dark">
                        <i className='fa-spin fa-spinner fa-lg fas'/>
                    </div>
                </div>
            </form>
        </Row>
        { companies &&
        <div id="table-container" className="my-4 bg-white text-start rounded">
            <div className="border-bottom fw-semibold d-none d-md-block">
                <Row className="g-0 text-dark">
                    <Col md={3} className=" py-md-3 ps-2 ps-md-3 pe-2 pe-md-1 text-nowrap">Raison sociale</Col>
                    <Col md={3} className=" py-md-3 px-2 px-lg-1">Dirigeant</Col>
                    <Col md={1} lg={2} className="py-md-3 px-2 px-lg-1 text-nowrap">Code APE</Col>
                    <Col md={1} className=" py-md-3 px-2 px-lg-1 text-md-center">Dpt.</Col>
                    <Col md={2} lg={1} className="py-md-3 px-2 px-lg-1 text-md-center">Création</Col>
                    <Col md={2}></Col>
                </Row>
            </div>
            <div id="search-company-table-body" className={`rounded ${loadingCompanies ? 'opacity-30' : ''}`}>
                    { companies?.map((company, i) => (
                        company && (
                        <Row key={i} className="row g-0 position-relative align-items-center hover-light-grey-lighter py-2 py-md-0 company-result border-bottom cursor-pointer" onClick={() => redirectParentTo(i, `https://infonet.fr/demandes/kbis-pro/?target=${company?.siret}`)}>
                            <Col md={3} className="py-0 py-md-2 ps-2 ps-md-3 pe-2 pe-md-1 mb-1 mb-md-0 text-primary fw-bold position-static text-uppercase">
                                <span className="text-decoration-none stretched-link">{ company.organization?.name }</span>
                            </Col>
                            <Col md={3} className="py-0 py-md-2 px-2 px-lg-1">
                                <span className="d-md-none fw-semibold">Dirigeant : </span>
                                { company.contacts?.main_corporate_officier?.name }
                            </Col>
                            <Col md={1} lg={2} className="py-0 py-md-2 px-2 px-lg-1 text-truncate">
                                <span className="d-md-none fw-semibold">Code APE : </span>
                                <span>{ company.organization?.activity?.ape_code }</span>
                                <small className="ms-1 d-inline d-md-none d-lg-inline">{ company.organization?.activity?.ape_name }</small>
                            </Col>
                            <Col md={1} className="py-0 py-md-2 px-2 px-lg-1 text-md-center">
                                <span className="d-md-none fw-semibold">Ville : </span>
                                <span className="d-md-none">{ company.organization?.address?.city } - </span>
                                {company.organization?.address?.postal_code?.substring(0, 2) ===  '97' ?
                                    company.organization?.address?.postal_code?.substring(0, 3) :
                                    company.organization?.address?.postal_code?.substring(0, 2)}
                            </Col>
                            <Col md={2} lg={1} className="py-0 py-md-2 px-2 px-lg-1 text-md-center">
                                <span className="d-md-none fw-semibold">Création :</span>
                                { company.organization?.creation_date?.substring(0, 4) }
                            </Col>
                            <Col md={2} className="d-none d-md-block py-0 py-md-2 pe-2 pe-md-3 ps-2 ps-md-1 text-end text-nowrap">
                                <span className="btn btn-dark btn-sm company-button">
                                    Sélectionner <i className={`${loadingUserRequest === i ? 'fa-spin fa-spinner' : 'fa-angle-right'} icon ms-1 fas`}/>
                                </span>
                            </Col>
                        </Row>)
                    )) }
                    { companies.length === 0 &&
                    <div className="card-body px-3 py-2">
                        <i className="loading-search me-1 fa-exclamation-triangle"/>
                        <span className="fw-normal">Aucun résultat</span>
                    </div> }
            </div>
            <nav id="company-search-pagination" className={loadingCompanies ? 'opacity-30' : ''}>
                <ul className={`pagination justify-content-center py-3 ${pages?.length > 0 ? '' : 'd-none'}`}>
                    { pages?.map((val, i) => (
                        <li key={i} className={`${val.class || ''} page-item cursor-pointer`} onClick={() => renderCompanies(val.page, true)}>
                            <span className="page-link cursor-pointer pagination-item" data-page-number={ val.page }>{ val.icon }</span>
                        </li>
                    )) }
                </ul>
            </nav>
        </div>}
    </>)
}

export default CompaniesComponent;

