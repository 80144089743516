
export const ReviewStarsComponent = ({ reviews }) => {
  const reviewsStarts = [1, 2, 3, 4, 5];

  return (
    <>
      {reviews?.reviews &&
        reviewsStarts.map((star, i) => (
          <span
            key={i}
            className={`d-inline-block text-${
              i < parseInt(reviews.average) ? "primary" : "light-grey"
            }`}
          >
            <i className="fa-star fas" />
          </span>
        ))}
    </>
  );
};
