import ModalVideoComponent from "./ModalVideoComponent";
import GoodMorningIMG from "../assets/images/goodmorningbusiness-visuel.png";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

export const GoodMorningBusiness = () => {
    const { t }= useTranslation();
    return (
        <Row className="bg-light h-100 no-gutters position-relative cursor-pointer h-100">
            <Col className="d-flex align-items-end pt-1">
                <img src={GoodMorningIMG} title={t('good-morning-widget.alt')} alt={t('good-morning-widget.alt')} className="img-fluid" />
            </Col>
            <Col xs="auto" className="position-static text-center ps-3 pe-4 d-flex align-items-center">
                <div className="position-static w-100">
                    <span className="h3 text-uppercase d-block mb-0">{t('good-morning-widget.title')}</span>
                    <span className="h4 d-block mb-2">{t('good-morning-widget.sponsor')}</span>
                    <ModalVideoComponent />
                    <small className="d-block fw-bold mt-2">{t('good-morning-widget.days')}</small>
                    <small className="d-block fw-bold">{t('good-morning-widget.hours')}</small>
                    <small className="d-block mt-1">{t('good-morning-widget.channel')}</small>
                </div>
            </Col>
        </Row>
    )
}