import { useTranslation, Trans } from "react-i18next";
import { useState, useEffect } from "react";
import AvisLogo from "../../assets/images/logo-avis-verifies.svg";
import { ReviewStarsComponent } from "./ReviewsStarsComponent";
import { Col, Row, ProgressBar } from "react-bootstrap";

export const ReviewsProgressComponent = () => {
  const [reviews, setReviews] = useState(null);
  const reviewsUrl = process.env.REACT_APP_URL_REVIEWS;
  console.log(reviewsUrl);

  useEffect(() => {
    fetch(reviewsUrl)
      .then((response) => response.json())
      .then((reviews) => setReviews(reviews))
      .catch((error) => null);
  }, [reviewsUrl]);

  const { t } = useTranslation();
  const total = reviews?.total;
  const satisfaction = reviews?.satisfaction;

  function setProgressBar(satisfaction) {
    return parseInt((satisfaction/total)*100);
  }
  
  return (
    <div className="d-flex align-items-center bg-light rounded-3 py-3 px-4 h-100">
      <div id="collapse-reviews" className="w-100">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <a
              href="https://www.avis-verifies.com/avis-clients/infonet.fr"
              title="Avis vérifiés"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={AvisLogo}
                alt="{{ 'reviews.provider'|trans }}"
                width="95"
              />
            </a>
            <span id="reviews-total" className="d-block">
              <Trans i18nKey="reviews-widget.total" >{{total}}</Trans>
            </span>
          </div>
          <div className="text-end ms-auto">
            <span className="d-block h1 fw-bolder mb-0">{`${parseInt(
              reviews?.average
            )}/5`}</span>
            <ReviewStarsComponent reviews={reviews} />
          </div>
          <div>
            <span
              id="reviews-average"
              className="h1 fw-bolder text-end mb-0"
            ></span>
            <div id="review-stars"></div>
          </div>
        </div>
        <Row className="mx-n2 d-flex align-items-center">
          <Col xs="auto"
            className="px-2 small fw-bold text-uppercase"
            style={{ width: "90px" }}
          >
            {t("reviews-widget.rating.excellent")}
          </Col>
          <Col className="px-2">
            <ProgressBar className="rounded-3" now={setProgressBar(satisfaction?.good)} variant="success" style={{ height: "6px" }}/>
          </Col>
        </Row>
        <Row className="mx-n2 mt-2 d-flex align-items-center">
          <Col xs="auto"
            className="col-auto px-2 small fw-bold text-uppercase"
            style={{ width: "90px" }}
          >
            {t("reviews-widget.rating.veryGood")}
          </Col>
          <Col className="px-2">
            <ProgressBar className="rounded-3" now={setProgressBar(satisfaction?.medium)} variant="success" style={{ height: "6px" }}/>
          </Col>
        </Row>
        <Row className="mx-n2 mt-2 d-flex align-items-center">
          <Col xs="auto"
            className="px-2 small fw-bold text-uppercase"
            style={{ width: "90px" }}
          >
            {t("reviews-widget.rating.good")}
          </Col>
          <Col className="px-2">
            <ProgressBar className="rounded-3" now={setProgressBar(satisfaction?.bad)} variant="success" style={{ height: "6px" }}/>
          </Col>
        </Row>
        <p className="mb-0 smaller text-muted mt-3">
          {t("reviews-widget.legals")}
        </p>
      </div>
    </div>
  );
};
