import React from "react";
import WidgetsComponent from "./components/WidgetsComponent";
import CompaniesComponent from "./components/CompaniesComponent";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

const queryString = new URLSearchParams(window.location.search);
const tags = [
  "Analyse",
  "RCS",
  "Actes",
  "Extrait Kbis",
  "NºTVA",
  "Status",
  "Bilans",
  "Duns",
  "Bodacc",
  "Procédures collectives",
];
const HomePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-primary py-3 py-md-4 mb-3 mb-lg-4 text-center">
        <Container fluid="lg">
          <span className="mb-2 display-2 text-white">
            {t("home-page.title")}
          </span>
          <span className="d-block display-1 text-white fw-bold mb-3">
            {t("home-page.telecharge")}
            <br className="d-md-none" />
          </span>
          <ul className="list-inline mb-0">
            {tags.map((tag, i) => (
              <li key={i}
                className={`${tag === "Extrait Kbis" ? "bg-dark" : " bg-dark-green"} list-inline-item mb-1 badge text-uppercase text-white`}
              >
                #{tag}
              </li>
            ))}
          </ul>
          <CompaniesComponent />
          <span className="d-block mb-1 text-white">{t("home-page.congratulations")} <b>{t("home-page.infonet")}</b></span>
          <span className="h4 mb-1 fw-normal text-white">{t("home-page.b2b")} {t("home-page.reserved")}</span>
          <span className="d-block mb-1 text-white opacity-70 small">{t("home-page.privateService")}<br />{t("home-page.providedInfo")}</span>
          <span className="d-block mb-1 text-white small">{t("home-page.deliver")}</span>
        </Container>
      </div>
      {!queryString.get("query") && <WidgetsComponent />}
    </>
  );
};

export default HomePage;
